/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Image } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Potvrdit účast"}>
        <SiteHeader set="cs" currentLanguage={0} />

        <Column className="--center pb--80 pt--80" name={"potvrdit-ucast-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1090}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper className="pt--40">
              
              <Title className="title-box title-box--center fs--36 mt--10" content={"Potvrďte, prosím, svou účast"}>
              </Title>

              <Text className="text-box ff--1 fs--18" style={{"maxWidth":600}} content={"Pro mimopražské hosty je ubytování zajištěno.<br><br>Prožijete s námi naši kouzelnou svatbu? Vyplňte, prosím, informace níže.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"potvrdit-ucast"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 ff--2 fs--16" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé Vaše jméno"},{"name":"Jména další","type":"textarea","placeholder":"Kolik osob, jednotlivá jména, u dětí uveďte věk"},{"name":"Alergie","type":"text","required":true,"placeholder":"Máte jakákoli stravovací omezení?"},{"name":"Email","type":"email","required":true,"placeholder":"E-mail"},{"name":"Adresa","type":"text","required":true,"placeholder":"Poštovní adresa"},{"name":"Oblíbená písnička","type":"text","placeholder":"Název Vaší oblíbené písničky"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"fotogalerie"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1150}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"V případě dalších dotazů se prosím obraťte na naši svatební koordinátorku.<br>Eva: eva@ifconcept.cz"}>
              </Text>

              <Image style={{"maxWidth":""}} src={"https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=350x_.png 350w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=660x_.png 660w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=860x_.png 860w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=1400x_.png 1400w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=2000x_.png 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--40" name={"paticka-2"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1090}} anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36 mt--10" content={"Těšíme se na Vás!"}>
              </Title>

              <Text className="text-box text-box--center mt--10" content={"<a href=\"/potvrdit-ucast\">Petra &amp; Petr</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}